import { ElectivePool, SelectedElective } from 'src/types/electives';

import { Box, Grid, useTheme } from '@mui/material';
import { ExpansionPanel } from '@pennfoster/pfc-design-system/molecules/expansion-panel';

import ElectivePoolHeader from './elective-pool-header';
import ElectiveExpansionRow, { ElectiveExpansionRowProps } from './expansion-row';
import MessageDialogue from 'src/components/message';
import { MutationStatus } from 'react-query';

interface Props {
  hasError: boolean;
  electivePool: ElectivePool;
  selectedCourseIds: Set<number>;
  selectedCourses: SelectedElective[];
  nNeededInPool: number;
  mutationStatus: MutationStatus;
  hasSelectedMax: boolean;

  handleSelectElective: (item: SelectedElective) => void;
}

export default function ElectivePoolComponent({
  hasError,
  electivePool,
  selectedCourseIds,
  selectedCourses,
  hasSelectedMax,
  mutationStatus,
  handleSelectElective,
  ...props
}: Props) {
  const { palette } = useTheme();
  const { sisElectivePoolId } = electivePool;
  const expansionRows = electivePool.courses.map((course) => {
    const subprops: ElectiveExpansionRowProps = {
      course,
      mutationStatus,
      isEnrolled: course.isEnrolled,
      isSelected: selectedCourseIds.has(course.sisCourseId),
      isAddable:
        course.isAvailable &&
        !selectedCourses.some((selectedCourse) => selectedCourse.sisCourseId === course.sisCourseId) &&
        !hasSelectedMax,
      selectElective: () => {
        handleSelectElective({
          courseName: course.name,
          sisCourseId: course.sisCourseId,
          sisElectivePoolId,
        });
      },
      ...props,
    };
    return <ElectiveExpansionRow key={`electives-expansion-row-${course.sisCourseId}`} {...subprops} />;
  });
  return (
    <Grid
      item
      xs={12}
      md={7}
      sx={{
        alignContent: 'flex-start',
        mb: 3,
        backgroundColor: palette.background.paper
      }}
    >
      <Box sx={{ mb: 3, mr: 3 }}>
        <ElectivePoolHeader {...electivePool} {...props} />
        {hasError && (
          <MessageDialogue
            type="error"
            message={`We’re sorry, we are having trouble loading these courses at the moment. Please refresh the page or contact support for assistance.`}
          />
        )}
        <ExpansionPanel dataColumnWidth={2} mainColumnWidth={4} singleExpand>
          {expansionRows}
        </ExpansionPanel>
      </Box>
    </Grid>
  );
}
