export const ROOT = '/';
export const DASHBOARD = '/';
export const PAYMENTS_OVERVIEW = '/payments-overview';
export const MANAGE_MY_BILL = '/manage-my-bill';
export const PAY_BALANCE = '/pay-balance';
export const MAKE_A_PAYMENT = `${PAYMENTS_OVERVIEW}/make-a-payment`;
export const MANAGE_PAYMENT = `${PAYMENTS_OVERVIEW}/manage-payment`;
export const COURSES = '/courses';
export const ELECTIVES = `${COURSES}/electives`;
export const PATHWAY = `${COURSES}/pathway`;
export const ADMIN = '/admin';
export const AUTH = '/auth';
export const AUTH_CALLBACK = '/auth/callback';
export const AUTH_LOGOUT_CALLBACK = '/auth/logout/callback';
export const AUTH_RESET_PASSWORD = '/auth/reset-password';
export const AUTH_FORGOT_SIGNIN = '/auth/forgot-signin';
export const AUTH_HELP = '/auth/help';
export const AUTH_LOGIN = '/auth/login';
export const AUTH_ADMIN = '/auth/admin';
export const AUTH_LOGOUT = '/auth/logout';
export const CONFIRMATION_PAYMENT = 'confirmation-payment/:value';
export const PROFILE = '/profile';

export const UNAUTHORIZED = '/unauthorized';
export const ERROR = '/error';
export const SESSION_TIMEOUT = '/session-timeout';
export const PROCESSING_ENROLLMENT = '/processing-enrollment';
export const AUTH_ERROR = '/auth/error';
// baseUrl is used to allow localhost to override api location
const baseUrl = location.hostname === 'localhost' ? `${process.env.REACT_APP_LOCAL_API_OVERRIDE}` : '';
export const LC_API = `${baseUrl}/api`;

// These hub URLS will not work locally. we let them fail. should not effect app function -Bryan O
export const SIGNALR_MESSAGES = `/hubs/messages`;
export const SIGNALR_FE_EVENTS = `/hubs/fe-events`;

export default {
  ROOT,
  DASHBOARD,
  PAYMENTS_OVERVIEW,
  MANAGE_MY_BILL,
  PAY_BALANCE,
  MAKE_A_PAYMENT,
  MANAGE_PAYMENT,
  COURSES,
  ELECTIVES,
  ADMIN,
  AUTH,
  AUTH_CALLBACK,
  AUTH_LOGOUT_CALLBACK,
  AUTH_LOGIN,
  AUTH_LOGOUT,
  CONFIRMATION_PAYMENT,
  PROFILE,
  PATHWAY,
  UNAUTHORIZED,
  ERROR,
  SESSION_TIMEOUT,
  PROCESSING_ENROLLMENT,
  AUTH_RESET_PASSWORD,
  AUTH_FORGOT_SIGNIN,
  AUTH_HELP,
  AUTH_ERROR,
  AUTH_ADMIN,
};
