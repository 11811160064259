import { User } from 'src/types/user';
import { BaseSignalRConnection } from './base-signalr-connection';
import { InboxMessage } from 'src/types/message';
import { SIGNALR_MESSAGES } from 'src/routes/routemap';

class Messages extends BaseSignalRConnection {
  constructor() {
    super(SIGNALR_MESSAGES || '');
    this.init();
    setInterval(() => this.checkAndReconnect(), 5000);
  }

  // Override the startConnection method to include Messages-specific logic
  public async startConnection(userId = ''): Promise<void> {
    await super.startConnection(); // Ensure the base connection logic is executed

    if (this.isConnected) {
      // Perform message-specific setup, like registering a user
      this.registerUser(userId);
    }
  }

  public registerUser(userId: string): void {
    if (userId) {
      this._connection.invoke('Register', userId).catch((error) => {
        console.error('Error sending registration:', error);
      });
    }
  }

  public events(onMessageReceived: (message: InboxMessage) => void): void {
    this._connection.on('ReceiveMessage', (message: InboxMessage) => {
      onMessageReceived(message);
    });
  }

  public userReady(onUserReady: (user: User) => void): void {
    this._connection.on('UserReady', (user: User) => {
      onUserReady(user);
    });
  }

  public async initUser(userId: string): Promise<void> {
    if (this.isConnected) {
      this.connection.send('Register', userId);
      return;
    }

    try {
      await this.connection.start();
      this._isConnected = true;
      this.connection.send('Register', userId);
    } catch (error: unknown) {
      console.error('Error starting connection:', error);
      this._isConnected = false;
    }
  }

  private async init(): Promise<void> {
    // Initialize with a user ID if available, otherwise attempt to fetch from sessionStorage
    const userId = sessionStorage.getItem('userId') || '';
    await this.startConnection(userId);
  }

  private async checkAndReconnect(): Promise<void> {
    // Only attempt to reconnect if not already connected
    if (!this.isConnected) {
      await this.init();
    }
  }
}

export default new Messages();
