import { Body1, HeadingH6 } from 'src/styles';

import { Grid } from '@mui/material';

const ElectivesHeader = () => {
  return (
    <Grid item xs={12} md={7} sx={{ mb: 2 }}>
      <HeadingH6 sx={{ mb: 1 }} variant="h1">
        Choose Your Electives
      </HeadingH6>
      <Body1 variant={'caption'}>
        Congratulations! You are now eligible to choose your elective courses.
        Explore new subjects and tailor your education to your interests! You
        can select one elective at a time or all at once.
      </Body1>
    </Grid>
  );
};

export default ElectivesHeader;
