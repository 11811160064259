import { TokenObject } from '@pennfoster/pfc-design-system/types/token';
import axios, { AxiosResponse } from 'axios';
import { LC_API } from 'src/routes/routemap';

export function getTheme(themeName: string): Promise<AxiosResponse<TokenObject>> {
  return axios({
    method: 'GET',
    url: `${LC_API}/theme?themeName=${themeName}`,
  });
}

export function getLogo(logoName: string): Promise<AxiosResponse<string>> {
  return axios({
    method: 'GET',
    url: `${LC_API}/logo?logoName=${logoName}`,
  });
}
