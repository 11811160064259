import { SISCourseId } from 'src/types/course';
import { DegreeAuditTerm } from 'src/types/degree-audit-term';
import { ElectivePool, SelectedElective } from 'src/types/electives';

import { Box, Grid } from '@mui/material';

import ElectivePoolComponent from './elective-pool-component';
import { MutationStatus } from 'react-query';

export interface ElectivesSelectionBoxProps {
  hasSelectedMax: boolean;
  currentTerm: DegreeAuditTerm;
  electivePools: ElectivePool[];
  enrolledElectiveNames: Set<string>;
  selectedCourses: SelectedElective[];
  mutationStatus: MutationStatus;
  handleSelectElective: (item: SelectedElective) => void;
}

export default function ElectivesSelectionBox({
  hasSelectedMax,
  electivePools,
  selectedCourses,
  mutationStatus,
  ...props
}: ElectivesSelectionBoxProps) {
  const { currentTerm } = props;

  const epComponents = electivePools.map((electivePool, i) => {
    const { sisElectivePoolId, numberCoursesRequired, numberCoursesEnrolled, courses } = electivePool;
    let subprops = {
      hasError: false,
      electivePool,
      selectedCourseIds: new Set<SISCourseId>(),
      selectedCourses,
      nNeededInPool: -1,
      mutationStatus,
      hasSelectedMax: false,
    };
    if (numberCoursesRequired < 0) {
      subprops.hasError = true;
      return <ElectivePoolComponent key={i} {...props} {...subprops} />;
    }

    if (numberCoursesRequired <= 0) return;
    currentTerm.electives.filter((e) => {
      return courses.some((c) => c.isEnrolled);
    }).length;

    const nNeededInPool = numberCoursesRequired - numberCoursesEnrolled;

    const selectedCourseIds: Set<SISCourseId> = new Set(
      selectedCourses.filter((item) => item.sisElectivePoolId === sisElectivePoolId).map((item) => item.sisCourseId)
    );

    subprops = {
      hasError: false,
      electivePool,
      selectedCourseIds,
      selectedCourses,
      nNeededInPool,
      mutationStatus,
      hasSelectedMax: hasSelectedMax || selectedCourseIds.size >= nNeededInPool,
    };

    if (nNeededInPool > 0) {
      return <ElectivePoolComponent key={i} {...props} {...subprops} />;
    }
  });

  return (
    <Box sx={{
      overflowX: 'auto',
    }}>
      <Grid container direction="column">
        {epComponents}
      </Grid>
    </Box>
  );
}
