import { styled } from '@mui/material';
import {
  ExpansionRow,
  ExpansionSummary,
} from '@pennfoster/pfc-design-system/molecules/expansion-panel';

export const StyledExpansionRow = styled(ExpansionRow)(({ theme }) => ({
  width: '100%',
  padding: '17px 48px 24px 48px',
  backgroundColor: theme.palette.neutral[90],
  '&.MuiGrid-root .MuiGrid-item': {
    flexFlow: 'column',
    maxWidth: '100%',
    flexBasis: '100%',
  },
}));

export const StyledExpansionSummary = styled(ExpansionSummary)(({ theme }) => ({
  [theme.breakpoints.down('sm')]: {
    width: '50%',
  },
  '.MuiGrid-root.MuiGrid-container': {
    alignItems: 'center',
  },
  '.MuiGrid-root .MuiGrid-item': {
    justifyContent: 'flex-end',
  },
}));
