import React from 'react';
import {
  Box,
  Button as ButtonMUI,
  Radio,
  RadioGroup,
  useTheme,
} from '@mui/material';
import { Button } from '@pennfoster/pfc-design-system';
import { LabelMedium, PrimaryCardTitleBillForm, Subtitle1 } from 'src/styles';
import { PaymentMethod } from 'src/types/payments';
import CreditCardPayment from 'src/components/credit-card-payments';
import FeEvents from 'src/events/fe-events';
import { AddCard } from '@mui/icons-material';
import { User } from 'src/types/user';
import ChoosePaymentMethodSkeleton from './skeleton/choose-payment-method-skeleton';

interface ChoosePaymentMethodProps {
  isChanged: boolean;
  handleClickChange: () => void;
  paymentMethods: PaymentMethod[];
  onClickPaymentMethod: (paymentMethod: PaymentMethod) => void;
  defaultCard: PaymentMethod | undefined;
  setAddPaymentMethodOpen: (isOpen: boolean) => void;
  user: User | undefined;
}

export default function ChoosePaymentMethod({
  isChanged,
  handleClickChange,
  paymentMethods,
  onClickPaymentMethod,
  defaultCard,
  setAddPaymentMethodOpen,
}: ChoosePaymentMethodProps) {
  const theme = useTheme();
  return paymentMethods.length === 0 ? (
    <ChoosePaymentMethodSkeleton />
  ) : (
    <>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
        }}
      >
        <Subtitle1 variant="h2">
          {!isChanged ? 'Payment Method' : 'Change Payment Method'}
        </Subtitle1>

        {/*TODO: Once the Button component from Design System is already fixed (When the state change, 
                  the text of the component should change), use that component instead the Button component from MUI
                */}
        <ButtonMUI
          sx={{ textTransform: 'capitalize' }}
          onClick={handleClickChange}
        >
          {!isChanged ? (
            <LabelMedium>Change</LabelMedium>
          ) : (
            <LabelMedium sx={{ color: 'grey' }}>X</LabelMedium>
          )}
        </ButtonMUI>
      </Box>

      {isChanged && (
        <Box sx={{ pt: 2 }}>
          <RadioGroup sx={{ pt: 1 }} aria-label="Payment Methods">
            {paymentMethods &&
              paymentMethods.map((paymentMethod) => (
                <Box
                  key={paymentMethod?.id}
                  sx={{
                    alignContent: 'center',
                    alignItems: 'center',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-between',
                  }}
                >
                  <Box
                    sx={{
                      display: 'flex',
                      flexDirection: 'row',
                      width: '100%',
                      alignItems: 'center',
                      alignContent: 'center',
                    }}
                  >
                    <Radio
                      value={paymentMethod?.id}
                      onClick={() => {
                        onClickPaymentMethod(paymentMethod);
                      }}
                      inputProps={{
                        'aria-label': `Type: ${paymentMethod?.brand
                          }. Last four digits: ${paymentMethod?.id?.toString()}`,
                      }}
                    />
                    <Box sx={{ marginLeft: theme.spacing(2) }}>
                      <CreditCardPayment {...paymentMethod} />
                    </Box>
                    <Box
                      sx={{
                        marginLeft: 'auto',
                        marginRight: '25px',
                      }}
                    >
                      <PrimaryCardTitleBillForm>
                        {paymentMethod?.expiration}
                      </PrimaryCardTitleBillForm>
                    </Box>
                  </Box>
                </Box>
              ))}
          </RadioGroup>
        </Box>
      )}
      {!isChanged && (
        <Box sx={{ marginBottom: theme.spacing(5) }}>
          <CreditCardPayment {...defaultCard} />
        </Box>
      )}

      {(!defaultCard || isChanged) && (
        <Box sx={{ flexGrow: 1, mt: '8px' }}>
          <Button
            label="Add a new card"
            pfVariant="text"
            icon={<AddCard />}
            onClick={() => {
              setAddPaymentMethodOpen(true);
              FeEvents.trackNamedEvent({
                eventName: 'AddPaymentMethodClick',
              });
            }}
          />
        </Box>
      )}
    </>
  );
}
